<template>
  <div class="orderlist">
    <el-card v-if="$route.query.model_type==2||$route.query.model_type==4||$route.query.model_type==8" class="mb-2">
      <div class="d-flex justify-content-between">
        <div style="cursor:pointer" :class="eventStatus===''?'checkTab':''" @click="changeTab('')">All</div>
        <div style="cursor:pointer" v-for="(item,index) of orderStatus" :key="index" @click="changeTab(item.value)" :class="eventStatus===item.value?'checkTab':''">{{item.label}}</div>
      </div>
    </el-card>
    <section v-if="loading">
      <el-skeleton animated>
        <template slot="template">
          <div class="iq-card p-2" v-for="i of 4" :key="i" >
            <div class="d-flex justify-content-between pb-2">
              <el-skeleton-item variant="text" style="width:100px;margin-top:15px;" />
              <el-skeleton-item variant="text" style="width:180px;margin-top:15px;" />
              <el-skeleton-item variant="text" style="width:80px;margin-top:15px;" />
            </div>
            <div class="d-flex">
              <el-skeleton-item variant="image" style="width:120px; height: 120px;" />
              <div class="d-flex flex-column ml-2">
                <el-skeleton-item class="mb-2 ml-2" variant="text" style="width:320px;" />
                <el-skeleton-item class="mb-2 ml-2" variant="text" style="width:220px;" />
                <el-skeleton-item class="mb-2 ml-2" variant="text" style="width:200px;" />
                <el-skeleton-item class="ml-2" variant="text" style="width:240px;" />
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <el-skeleton-item variant="text" style="width:100px;margin-top:15px;" />
              <el-skeleton-item variant="text" style="width:80px;height:35px; margin-top:15px;" />
            </div>
          </div>
        </template>
      </el-skeleton>
    </section>
    <section v-else >
      <div v-if="dataList.length > 0">
        <div>
          <b-card v-for="(item, index) in dataList" :key="index" class="mb-3">
            <!-- 商品标题 -->
            <div class="row align-items-center">
              <div class="col-3 title">
                <h5 class="d-flex align-items-center">
                  <i :class="modelIcon[model_type].icon" class="mb-1" style="color: #50b5ff"></i>
                  <span class="ml-2">{{modelIcon[model_type].type }}</span>
                </h5>
              </div>
              <div class="col-6 text-center">
                Order Number :
                {{ item.order_sn||item.order_no | filterSplice25 }}
              </div>
              <div class="col-3 text-right pending">
                <span style="color: #f56c6c" v-if="item.order_status == 0">Not Paid</span>
                <span style="color: #81cc5b" v-if="item.order_status == 1">Paid</span>
                <span style="color: #eab25d" v-if="item.order_status == 2">Free</span>
                <span style="color: #eab25d" v-if="item.order_status == 3">Pending</span>
                <span style="color: #22a1ff" v-if="item.order_status == 4">Refunding</span>
                <span style="color: #eab25d" v-if="item.order_status == 5">Refunded</span>
                <span style="color: #b6b8bc" v-if="item.order_status == 6">Cancel</span>
              </div>
            </div>
            <hr />
            <div>
              <div data-toggle="modal">
                <div class="row pb-2 b-border" v-if="model_type==5">
                  <div class="col-md-12 col-sm-12 pl-md-0 pl-sm-5 userName">
                    <div class="titles d-flex flex-column ml-2">
                      <div class="wodl">
                        <span style="color: #333;">Type: </span>
                        <span>{{ item.type?'Probationary Member':'Member' }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Organization: </span>
                        <span>{{ item.organization }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Created At: </span>
                        <span>{{ item.created_at }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pb-2 b-border" v-else-if="model_type==8">
                  <div class="col-md-12 col-sm-12 pl-md-0 pl-sm-5 userName">
                    <div class="titles d-flex flex-column ml-2">
                      <div class="wodl">
                        <span style="color: #333;">Donation Type: </span>
                        <span>{{ {1:'JCI',2:'Foundation',3:'Other'}[item.donated_type] }}</span>
                      </div>
                      <div class="wodl" >
                        <span style="color: #333;">Donate To: </span>
                        <span>{{ item.donate_to }}</span>
                      </div>
                      <div class="wodl" >
                        <span style="color: #333;">Donation Amount: </span>
                        <span>{{item.currency?'€':'$'}} {{ item.order_amount }}</span>
                      </div>
                      <div class="wodl" >
                        <span style="color: #333;">Commission: </span>
                        <span>{{item.currency?'€':'$'}} {{ item.fee }}</span>
                      </div>
                      <div class="wodl">
                        <span style="color: #333;">Created At: </span>
                        <span>{{ item.created_at }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row pb-2 b-border cursor-pointer" v-else @click="toDetail(item)">
                  <div class="col-md-3 col-sm-12 pb-2 text-center">
                    <el-image :src="item.cover" cover class="elimageBox">
                      <div slot="error" class="image-slot">
                        <img :src="errorImage" class="errorpng" alt="" />
                      </div>
                    </el-image>
                  </div>

                  <div class="col-md-9 col-sm-12 pl-md-0 pl-sm-5 userName">
                    <div class="titles d-flex flex-column">
                      <div class="wodl">
                        <span style="color: #333;">Title: </span>
                        <span>{{ item.title }}</span>
                      </div>
                      <section v-if="model_type!=6">
                        <div class="wodl">
                          <span style="color: #333;">Package Type: </span>
                          <span v-if="model_type==2||model_type==4">{{ item.order_type==1?'Package':'Single' }}</span>
                          <span v-else>{{ item.package_type==2?'Package':'Single' }}</span>
                        </div>
                        <div class="wodl" v-if="item.order_type==1">
                          <span style="color: #333;">Package Name: </span>
                          <span>{{ item.setmeal_title }}</span>
                        </div>
                      </section>
                      <section v-if="model_type==2||model_type==4">
                        <div class="wodl">
                          <span style="color: #333;">Quantity: </span>
                          <span>{{ item.number }}</span>
                        </div>
                        <div class="wodl">
                          <span style="color: #333;">Is Early Bird: </span>
                          <span>{{ item.is_use_bird_price == 0 ? "No" : "Yes" }}</span>
                        </div>
                      </section>
                      <div class="wodl">
                        <span style="color: #333;">Created At: </span>
                        <span>{{ item.created_at }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 总价 -->
              <div class="row d-flex align-items-center">
                <div class="col-md-3 col-sm-3 text-left d-flex align-items-center">
                  <section v-if="model_type==8">
                    <div class="mt-2">Total: <strong> {{item.currency?'€':'$'}} {{ item.pay_amount }}</strong></div>
                  </section>
                  <section v-else>
                    <p class="mt-1 mb-0" v-if="item.order_status == 2">
                      {{ $t("Order.7bb@total") }}:
                      <strong> Free</strong>
                    </p>
                    <p class="mt-1 mb-0" v-else>
                      {{ $t("Order.7bb@total") }}:
                      <strong> {{item.price_unit?'€':'$'}}{{ item.order_amount||item.pay_money }}</strong>
                    </p>
                  </section>
                </div>

                <div class="col-md-9 col-sm-9 text-right orderPriceBt d-flex align-items-center justify-content-end">
                  <div class="countdownShow col-md-5 col-sm-5" style="display: flex" v-if="[4,6,8].includes(model_type)">
                    <div class="countdown_content" v-if="
                        item.countDownTime != 'Order closed' &&
                        item.order_status == 0&&item.countDownTime
                      ">
                      {{ $t("Order.d8a@countdown") }}:
                      <div style="color: red">
                        &nbsp; {{ item.countDownTime }}
                      </div>
                    </div>
                  </div>
                  <div class="countdownShow col-md-5 col-sm-5" style="display: flex" v-if="[2].includes(model_type)">
                    <div class="countdown_content" v-if="
                        item.order_status == 0&&item.timeDown !=0
                      ">
                      {{ $t("Order.d8a@countdown") }}:
                      <div style="color: red">
                        &nbsp; {{ item.timeDown }}
                      </div>
                    </div>
                  </div>

                  <div class="text-right col-md-7 col-sm-7" style="display: flex; justify-content: flex-end">
                    <div class="d-flex align-items-center cursor-pointer"
                      v-if="item.order_status == 1||item.order_status == 2">
                      <img style="width: 25px; height: 22px" @click="jumpInvoice(item.id)"
                        src="@/assets/images/icon/invoice.png" alt="" title="Invoice" />
                    </div>

                    <section class="d-flex align-items-center" v-if="model_type==5">
                      <button type="button" class="btn btn-primary ml-3" style="width: 100px"
                        v-if="item.order_status == 0" @click="PayEvent(item)">
                        {{ $t("Order.f2d@pay") }}
                      </button>

                      <button type="button" class="btn btn-outline-primary ml-3" style="width: 100px"
                        v-if="item.order_status == 0&&model_type==8" @click="DeleteEvent(item)">
                        {{ $t("Order.edb@delete") }}
                      </button>
                    </section>

                    <section class="d-flex align-items-center" v-else>
                      <button type="button" class="btn btn-outline-primary ml-3" style="width: 100px"
                        v-if="item.order_status == 0" @click="CancelEvent(item)">
                        {{ $t("Order.03e@cancel") }}
                      </button>

                      <button type="button" class="btn btn-primary ml-3" style="width: 100px"
                        v-if="item.order_status == 0" @click="PayEvent(item)">
                        {{ $t("Order.f2d@pay") }}
                      </button>

                      <button type="button" class="btn btn-outline-primary ml-3" style="width: 100px"
                        v-if="item.order_status == 2 && item.is_refund && [3,4,5,7].includes(item.pay_type)"
                        @click="RefundEvent(item)">
                        Refund
                      </button>

                      <button type="button" class="btn btn-outline-primary ml-3" style="width: 100px"
                        v-if="item.order_status == 6" @click="DeleteEvent(item)">
                        {{ $t("Order.edb@delete") }}
                      </button>
                      <button type="button" class="btn btn-primary ml-3" style="width: 100px" @click="checkEvent(item)">
                        Check
                      </button>
                    </section>

                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </div>

        <el-pagination background layout="prev, pager, next" :page-size="size" :total="totalNum"
          style="text-align: right; margin: 20px 0" @current-change="changePage" :current-page="page" hide-on-single-page>
        </el-pagination>
      </div>
    </section>

    <b-card v-if="dataList == ''">
      <div style="background:#fff;height: 500px" class="d-flex justify-content-center align-items-center">
        <img style="width:250px;" :src="emptypath" alt="" />
      </div>
    </b-card>

    <!-- 详情 弹窗 -->
    <b-modal id="Career" centered :title="stateType == 0 ? ' Order Details' : 'Refund rules'" hide-footer>
      <div class="row" v-if="stateType == 0">
        <div class="col-md-3 col-sm-5">
          <span>{{ $t("Order.967@order_time") }}:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left">
          {{ OrderDetailLsit.add_time }}
        </div>
        <div class="col-md-3 col-sm-5">
          <span>{{ $t("Order.14e@order_id") }}:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left">
          <span>{{ OrderDetailLsit.order_sn }}</span>
        </div>
        <!-- <div class="col-md-5 col-sm-5" v-if="
            OrderDetailLsit.pay_type != 0 && OrderDetailLsit.order_status == 2
          ">
          <span>Payment method:</span>
        </div> -->
        <div class="col-md-5 col-sm-5" v-if="
            OrderDetailLsit.pay_type != 0 && OrderDetailLsit.order_status == 2
          ">
          <span>Payment method:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left" v-if="
            OrderDetailLsit.pay_type != 0 && OrderDetailLsit.order_status == 2
          ">
          <!-- <span v-if="OrderDetailLsit.pay_type==0">undetermined </span> -->
          <span v-if="OrderDetailLsit.pay_type == 1">Free </span>
          <span v-if="OrderDetailLsit.pay_type == 2">Balance </span>
          <span v-if="OrderDetailLsit.pay_type == 3">Credit card </span>
          <span v-if="OrderDetailLsit.pay_type == 4">Offline individuals </span>
          <span v-if="OrderDetailLsit.pay_type == 5">Offline groups </span>
        </div>
        <div class="col-md-5 col-sm-5" v-if="
            OrderDetailLsit.pay_type == 3 && OrderDetailLsit.order_status == 2
          ">
          <span>{{ $t("Order.ddc@thirdparty_id") }}:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left" v-if="
            OrderDetailLsit.pay_type == 3 && OrderDetailLsit.order_status == 2
          ">
          {{ OrderDetailLsit.transaction_id }}
        </div>
        <div class="col-md-5 col-sm-5" v-if="
            OrderDetailLsit.pay_type != 0 &&
            OrderDetailLsit.pay_time &&
            OrderDetailLsit.order_status == 2
          ">
          <span>{{ $t("Order.234@payment_time") }}:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left" v-if="
            OrderDetailLsit.pay_type != 0 &&
            OrderDetailLsit.pay_time &&
            OrderDetailLsit.order_status == 2
          ">
          <span>{{ OrderDetailLsit.pay_time }}</span>
        </div>
        <!-- 退款完成展示 退款信息 -->
        <div class="col-5 col-sm-5" v-if="[5, 4, 8].includes(OrderDetailLsit.order_status)">
          <span>Approval time:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left" v-if="[5, 4, 8].includes(OrderDetailLsit.order_status)">
          {{ OrderDetailLsit.refund_time }}
        </div>
        <div class="col-md-5 col-sm-5" v-if="OrderDetailLsit.order_status == 5">
          <span>Refund Amount:</span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left" v-if="OrderDetailLsit.order_status == 5">
          {{ OrderDetailLsit.refund_amount }}
        </div>
        <div class="col-md-7 col-sm-7 text-md-right" v-if="OrderDetailLsit.pay_type == 0">
          <!-- <span v-if="OrderDetailLsit.pay_type==0">undetermined </span> -->
          <span v-if="OrderDetailLsit.pay_type == 1">{{ $t("Order.dbb@free") }}
          </span>
          <span v-if="OrderDetailLsit.pay_type == 2">{{ $t("Order.dde@balance") }}
          </span>
          <span v-if="OrderDetailLsit.pay_type == 3">{{ $t("Order.98c@credit_card") }}
          </span>
          <span v-if="OrderDetailLsit.pay_type == 4">{{ $t("Order.17e@offline_individuals") }}
          </span>
          <span v-if="OrderDetailLsit.pay_type == 5">{{ $t("Order.696@offline_groups") }}
          </span>
        </div>
        <div class="col-md-7 col-sm-7 text-md-left" v-if="
            OrderDetailLsit.order_status == 5 ||
            OrderDetailLsit.order_status == 8
          ">
          {{ OrderDetailLsit.refund_instructions }}
        </div>
      </div>

      <!--退款详情 -->
      <div v-else>
        <!-- <div v-for="(item, index) in RefundSum" :key="index">
          <p>
            {{
              `${index + 1}. Refund period:${
                item.refund_day
              }day. refund amount: $${item.refund_ratio}`
            }}
          </p>
        </div> -->
        <div>
          <span> 1.There is no refund after the event starts</span><br />
          <span>
            2.After payment.the activity can be cancelled in your order</span>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-secondary mr-3" style="width: 100px" @click="Cancelhide">
            {{ $t("Order.82e@cancel") }}
          </button>
          <button type="button" class="btn btn-primary" style="width: 100px" @click="RefundConfirm">
            {{ $t("Order.507@confirm") }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        emptypath: require("@/assets/images/group/empty1.png"), //     <!-- fail -->
        errorImage: require("@/assets/images/public/fail.png"),
        dataList: "",

        orderLists: "",
        OrderDetailLsit: {},
        stateType: 0,
        RefundSum: "",
        itemID: "",
        time: 400,
        record: 0,
        modelIcon: {
          2: { icon: "ri-calendar-event-line", type: "Event" },
          4: { icon: "ri-slideshow-3-line", type: "Meeting" },
          6: { icon: "ri-award-line", type: "Program" },
          5: { icon: "ri-vip-crown-line", type: "Membership" },
          8: { icon: "ri-money-dollar-circle-line", type: "Donation" },
        },
        page: 1,
        size: 10,
        totalNum: 0,
        type: 0,
        payType: 0,
        cType: "",
        model_type: 2,
        loading: true,
        orderStatus: [
          {label:"Not Paid", value: 0},
          {label: "Paid",value: 1},
          {label: "Free",value: 2},
          {label: "Pending",value: 3},
          {label: "Cancel",value: 6},
          {label: "Refunding",value: 4},
          {label: "Refunded",value: 5},
          ],
        eventStatus: "",
      };
    },
    // 计算属性
    computed: {},
    // 侦听器
    watch: {
      "$route.query.model_type"(newVal, oldVal) {
        this.model_type = Number(newVal);
        this.page = 1;
        this.dataList = [];
        this.getOrderList();
      },
    },

    // 组件实例创建完成，DOM未生成，
    created() {
      this.model_type = this.$route.query.model_type
      if (this.model_type) {
        this.model_type = Number(this.model_type)
      }
      this.getOrderList();
    },

    filters: {
      filterSplice25(val) {
        return val.slice(0, 25);
      },
      filterAwardType(val) {
        return val == 1 ? 'Program' : 'Program Certificate'
      }
    },
    mounted() { },

    methods: {
      // event order Tab
      changeTab(i){
        this.eventStatus = i;
        this.getOrderList();
      },
      checkEvent(item){
        // console.log(item);
        this.$router.push({
          path: "/orderDet",
          query: {
            id: item.id,
            type: this.model_type,
          },
        });
      },
      // 发票
      jumpInvoice(id) {
        this.$router.push({
          path: "/invoice",
          query: {
            type: this.model_type,
            id: id,
            sonType: 0,
          },
        });
      },

      changePage(item) {
        this.page = item;
        this.getOrderList();
      },

      getOrderList() {
        this.loading = true
        let ajaxType = {
          2: "eventOrderList",
          4: "meetingOrderList",
          8: "donationOrderList",
          6: "programOrderList",
          5: "vipOrderList",
        };
        this.$http[ajaxType[this.model_type]]({
          [this.$route.query.model_type==2?"order_status":this.$route.query.model_type==4?"order_status":this.$route.query.model_type==8?"order_status":""]:this.eventStatus,
          page: this.page,
          per_page: this.size,
        }).then((res) => {
          if (res.status == 200) {
            this.dataList = res.data.data || [];
            this.totalNum = res.data.total;
            this.dataList.forEach((item) => {
              if(item.remainder>0){
                this.eventDown(item)
              }
            })
            // 开始倒计时
            for (let i in this.dataList) {
              this.dataList[i].countDownTime = "";
              this.countDown(i);
            }
          }
          setTimeout(() => {
            this.loading = false
          }, 0)
        });
      },
      eventDown(item){
        let num = item.remainder
        setInterval(() => {
          if (num > 0) {
            num--
            let m = Math.floor(num/60)<10?"0"+Math.floor(num/60):Math.floor(num/60);
            let s = (num % 60) < 10 ? "0" + (num % 60) : (num % 60)
            item.timeDown = m + ":" + s
          }else{
            item.timeDown = 0
          }
        }, 1000);
        if(num<=0){
          clearInterval()
        }
      },
      getOrderDetail(id) {
        let ajaxType = {
          1: "getOrderDetail",
          2: "eventOrderDetail",
          3: "courseOrderDetail",
          4: "meetingOrderDetail",
          5: "courseCertificateOrderDetail",
          6: "courseCertificateOrderDetail",
          7: "getDonateOrderDetail",
          9: "programOrderDetail",
        };
        this.$http[ajaxType[this.model_type]]({ id: id }).then((res) => {
          if (res.status == 200) {
            this.OrderDetailLsit = res.data[0];
          }
        });
      },

      orderDetails(item) {
        this.stateType = 0;
        this.$bvModal.show("Career");
        this.getOrderDetail(item.id);
      },

      // 跳支付
      PayEvent(item) {
        this.$router.push({
          path: "/PayMoney",
          query: {
            model_type: this.model_type,
            id: item.pay_id||item.id,
            [[2, 4].includes(this.model_type) ? 'model_id' : '']: this.model_type == 2 ? item.event_id : item.meeting_id,
            [[2, 4].includes(this.model_type) ? 'package_type' : '']: item.package_type
          },
        });
      },

      // 取消
      CancelEvent(item) {
        let ajaxType = {
          2: "eventOrderCancel",
          4: "meetingOrderCancel",
          6: "cancelProgramOrder",
          8: "donationOrderCancel",
        };
        this.$confirm("Are you sure you want to cancel?", "Tips", {
          distinguishCancelAndClose: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(() => {
          this.$http[ajaxType[this.model_type]]({ id: item.id }).then((res) => {
            if (res.status == 200) {
              this.getOrderList();
            }
          });
        })
      },

      // 删除
      DeleteEvent(item) {
        let ajaxType = {
          2: "eventOrderDelete",
          4: "meetingOrderDelete",
          8: "donationOrderDelete",
          6: "delProgramOrder",
          5: "delVipOrder",
        };
        let data = { id: item.id }
        this.$confirm("Are you sure you want to delete the order", {
          cancelButtonText: "Cancel",
          confirmButtonText: "Confirm",
          type: "warning",
        }).then(() => {
          this.$http[ajaxType[this.model_type]](data).then((res) => {
            if (res.status == 200) {
              this.getOrderList();
            }
          });
        });
      },

      // 退款信息
      RefundEvent(item) {
        let newTime = new Date();
        if (newTime.getTime() - this.record > this.time) {
          if (item.is_refund == 0) {
            this.$message.warning("No refund is allowed for this order");
          } else {
            this.itemID = item.id;
            this.stateType = 1;
            this.$bvModal.show("Career");
          }
        }
        this.record = new Date().getTime();
      },

      // 确认申请退款
      RefundConfirm() {
        let ajaxType = {
          2: "eventRefundApply",
          4: "meetingRefundApply",
          6: "applyProgramRefund",
        };

        let newTime = new Date();
        if (newTime.getTime() - this.record > this.time) {
          this.$http[ajaxType[this.model_type]]({
            id: this.itemID,
          }).then((res) => {
            if (res.status == 200) {
              this.$bvModal.hide("Career");
              this.getOrderList();
            }
          });
        }
        this.record = new Date().getTime();
      },

      // 关闭退款
      Cancelhide() {
        this.$bvModal.hide("Career");
        // this.getOrderList(this.model_type)
      },

      // 倒计时
      TimeDifference(Stime, Ttime) {
        // 判断开始时间是否大于结束日期
        if (Stime > Ttime) {
          return false;
        }
        if (Ttime && Stime) {
          var startDate = new Date(Stime);
          var stopDate = new Date(Ttime);
          var startTime = startDate.getTime();
          var stopTime = stopDate.getTime();
          var cTime = Number(stopTime) - Number(startTime);
          var secondTime = cTime / 1000 / 60;
          return parseInt(secondTime);
        } else {
          return 0;
        }
      },

      countDownFun(time) {
        let tiemsd = time;
        let update_time = Math.round(new Date(tiemsd) / 1000);

        const end = update_time * 1000; // 后端返回时毫秒级
        const now = new Date(this.getCurrentTime()).getTime(); // 获取当前时间的毫秒数
        // console.log(end, '倒计时间');
        // console.log(now, '当前时间');
        const minus = now - end; // 获取  后端返回来 和当前时间差
        const m_30 = 10 * 60 * 1000; // 十分钟 毫秒级
        // console.log(m_30, '十分钟时间');
        const differ = m_30 - minus; //
        // console.log(differ, '倒计时开始时间');
        let m = parseInt((differ / 1000 / 60) % 60);
        let s = parseInt((differ / 1000) % 60);
        if (m >= 0 && s >= 0) {
          if (m == 0 && s == 0) {
            return "Order closed";
          } else {
            m = (m < 10 ? '0' : '') + m
            s = (s < 10 ? '0' : '') + s
            return m + ":" + s;
          }
        }
      },

      countDown(i) {
        let that = this;
        let item = that.dataList[i];

        // console.log(this.getCurrentTime())
        if (that.TimeDifference(item.created_at, this.getCurrentTime()) < 10) {
          that.dataList[i].countDownFn = setInterval(() => {
            if (
              that.TimeDifference(item.created_at, this.getCurrentTime()) < 10
            ) {
              item.countDownTime = that.countDownFun(item.created_at);

              that.$set(
                that.dataList,
                item.countDownTime,
                that.countDownFun(item.created_at)
              );
              if (item.countDownTime == "00:01") {
                location.reload();
              }
            } else {
              item.countDownTime = "Order closed";
              that.$set(that.dataList, item.countDownTime, "Order closed");
              // clearInterval(that.dataList[i].countDownFn); //清除定时器
            }
            if (item.countDownTime == "Order closed") {
              clearInterval(that.dataList[i].countDownFn); // 清除定时器
            }
          }, 1000);
        } else {
          item.countDownTime = "Order closed";
          that.$set(that.dataList, item.countDownTime, "Order closed");
        }
      },

      getCurrentTime() {
        // t为时区参数  默认东八区北京时间正  西为负

        let t;
        if (this.$getlocalStorage("time_zones")) {
          t = -6;
        } else {
          t = 8;
        }

        const time = new Date();
        const len = time.getTime();
        const offset = time.getTimezoneOffset() * 60000; // 本地时间与GMT时间差值
        const utcTime = len + offset; // 格林尼治时间

        const date = new Date(utcTime + 3600000 * t); // 格林尼治时间和当前时区差值
        const y = date.getFullYear();
        const mon = date.getMonth() + 1;
        const d = date.getDate();
        const h = t == -6 ? date.getHours() + 1 : date.getHours();
        const m = date.getMinutes();
        const s = date.getSeconds();
        //
        // 不足两位补0
        function addZero(value) {
          if (value < 10) return "0" + value;
          else return value;
        }
        const result =
          y +
          "-" +
          addZero(mon) +
          "-" +
          addZero(d) +
          " " +
          addZero(h) +
          ":" +
          addZero(m) +
          ":" +
          addZero(s);
        return result;
      },

      // -------------to项目/课程/活动/会议detail------------
      toDetail(item) {
        let typeId = {
          2: "event_id",
          4: "meeting_id",
          6: "program_id",
        };
        let path = {
          2: "/event-detail",
          4: "/meeting-details",
          6: "/award-detail",
        };
        this.$router.push({
          path: path[this.model_type],
          query: {
            id: item[typeId[this.model_type]],
            type: this.model_type,
          },
        });
      },

    },
  };
</script>

<style scoped lang='less'>
@media(max-width: 600px) {
  .col-3{
    font-size: 12px;
    h5 {
      font-size: 12px;
    }
  }
  .col-6{
    font-size: 10px;
  }
}
  ::v-deep #Career .modal-dialog {
    max-width: 600px !important;
  }

  .orderlist {
    ::v-deep .el-image__inner {
      vertical-align: top;
      object-fit: cover;
    }
    .checkTab {
      color: #50B5FF;
    }

    .elimageBox {
      width: 120px;
      height: 120px;
      background-color: #eff0f2;
      display: flex;
      align-items: center;
      justify-content: center;

      .errorpng {
        width: 38px;
        height: 31px;
      }
    }

    ::v-deep .ri-more-line:before {
      content: "\ee1d";
      color: #50b5ff;
      font-size: 27px;
      cursor: pointer;
    }

    .btn-outline-primary:hover {
      color: #50b5ff;
    }

    .userName {
      display: flex;
      align-content: space-between;
      flex-direction: column;
      height: 128px;

      .titles {
        /* height: 64px; */

        .wodl {
          height: 25px;
          display: -webkit-box;
          overflow: hidden;
          /* word-break: break-all; */
          text-overflow: ellipsis;

          white-space: pre-line;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
      }

      .titles-s {
        height: 64px;
        display: flex;
        align-items: center;
      }
    }

    .trip {
      margin-bottom: 10px;
      max-height: 250px;
      overflow-x: hidden;
      overflow: hidden;
      overflow-y: scroll;
    }

    .trip::-webkit-scrollbar {
      width: 4px !important;
    }

    .trip::-webkit-scrollbar-thumb {
      background-color: #d9d9d9 !important;
    }

    .trip::-webkit-scrollbar-thumb:hover {
      background: #747474 !important;
    }

    .writing {
      height: 100%;

      .title {
        display: -webkit-box;
        /* word-break: break-all; */
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-line;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }

    .orderPriceBt {
      display: flex;
      align-items: center;

      // justify-content: space-between;

      .countdownShow {
        display: inline-block;
        width: 160px;

        .countdown_content {
          display: flex;
          align-items: center;

          span {
            color: red;
            // max-width: 70px;
            text-align: left;
          }
        }
      }
    }
  }
</style>